import React, { Children } from 'react';
import './../App.css';

class DataGraphic extends React.Component{
    render(){
        return(
            <div className="data-graphic-section">
                <div className="data-graphic-circle" >
                    {this.props.children}
                </div>
                <span style={{width:'80%', margin:'0 auto'}} className="texto-m">
                    {this.props.descripcion}
                </span>
            </div>
        )
    }}
    export default DataGraphic;