import React from 'react';
import '../App.css';
import {Link} from 'react-router-dom';

import BannerHeader from '../BannerHeader';

import banner from '../images/1x/cellfinate_banner_preocupacion.jpg';
import banner_mobile from '../images/1x/cellfinate_banner_preocupacion_mobile.jpg';
import cellfinate from '../images/1x/cellfinate_imagen_cellfinate.jpg';
import profesionales from '../images/1x/cellfinate_imagen_profesionales.jpg';
import diagnostico from '../images/1x/cellfinate_imagen_diagnostico.jpg';
import DataGraphic from '../components/DataGraphic';
import MoreInfo from '../components/MoreInfo';
class Celulitis extends React.Component{
    render(){
        var banners = [
            {
                imagen: banner,
                imagen_mobile: banner_mobile
            }
        ]
    return( 
        <div className="Contenedor">
            <BannerHeader descripcion=''  banners={banners} />
            <div style={{backgroundColor:'white', minHeight:'400px', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}} >
                <div style={{width:'80%',  height:'300px',display:'flex',flexDirection:'column', alignItems: 'center', justifyContent:'space-around', fontSize:'1rem'}}>
                    <span className="texto-l texto-pink">
                        MÁS SOBRE LA CELULITIS
                    </span>
                    <span className="texto-m" style={{textAlign:'justify'}}>
                    Pero ¿qué es la celulitis? Se trata de un trastorno metabólico que surge por una alteración del tejido subcutáneo que se crea por la formación de nódulos adiposos, además de acumulación de agua y toxinas, todo unido a las alteraciones en la microcirculación local. Debido a ello se produce una modificación en la apariencia de la zona dando lugar a los característicos hoyuelos de la celulitis.
                    </span>
                </div>
            </div>
            <div style={{width:'100%', marginBottom:'5rem', backgroundColor:'white', minHeight:'500px', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-around'}} className="celulitis-data" >
                <DataGraphic descripcion="De las mujeres sufren de algún tipo de celulitis.">                
                    <svg width="100%" height="100%" viewBox="0 0 42 42" class="donut">
                        <circle class="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff"></circle>
                        <circle class="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#d2d3d4" stroke-width="4"></circle>
                        <circle class="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#CC483A" stroke-width="4" stroke-dasharray="98 2" stroke-dashoffset="25"></circle>
                    </svg>
                    <span className="texto-l texto-pink" style={{position:'absolute',left:'50%',top:'50%',transform:'translate(-50%,-50%)'}}>
                        98%
                    </span>
                </DataGraphic>
                <DataGraphic descripcion="Están dispuestas a realizarse algún tratamiento">
                    <svg width="100%" height="100%" viewBox="0 0 42 42" class="donut">
                        <circle class="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff"></circle>
                        <circle class="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#d2d3d4" stroke-width="4"></circle>
                        <circle class="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#CC483A" stroke-width="4" stroke-dasharray="90 10" stroke-dashoffset="25"></circle>
                    </svg>
                    <span className="texto-l texto-pink" style={{position:'absolute',left:'50%',top:'50%',transform:'translate(-50%,-50%)'}}>
                        9 / 10
                    </span>
                </DataGraphic>
                <DataGraphic descripcion="Mujeres con celulitis no van a la playa o no disfrutan de ello .">
                    <svg width="100%" height="100%" viewBox="0 0 42 42" class="donut">
                        <circle class="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff"></circle>
                        <circle class="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#d2d3d4" stroke-width="4"></circle>
                        <circle class="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#CC483A" stroke-width="4" stroke-dasharray="40 60" stroke-dashoffset="25"></circle>
                    </svg>
                    <span className="texto-l texto-pink" style={{position:'absolute',left:'50%',top:'50%',transform:'translate(-50%,-50%)'}}>
                        4 / 10
                    </span>
                </DataGraphic>                
            </div>
            <div style={{width:'100%', marginBottom:'5rem', backgroundColor:'white', minHeight:'500px',  justifyContent:'space-around'}} className="moreinfo">
                <MoreInfo image={cellfinate} title="CELLFINATE" text="Descubre los resultados de Cellfinate , el único procedimiento mínimamente invasivo que elimina los hoyuelos de la celulitis." >
                    <Link style={{fontSize:'1rem', width:'25%'}} to='/tratamiento' className="button-pink">VER MÁS</Link>
                </MoreInfo>
                <MoreInfo image={profesionales} title="PROFESIONALES" text="Encuentra a los profesionales de la salud más prestigiados que aplican el tratamiento en México." link="/tratamiento" >
                    <a style={{fontSize:'1rem', width:'25%'}} href="https://www.cellfinate.com.mx/doctor.html" target="_blank" rel="noopener noreferrer" className="button-pink">VER MÁS</a>
                </MoreInfo>
                <MoreInfo image={diagnostico} title="DIAGNÓSTICO" text="Existen diversos tipos de celulitis, evalúa si eres candidata para el tratamiento realizando este breve diagnóstico." >
                    <Link style={{fontSize:'1rem', width:'25%'}} to='/diagnostico' className="button-pink">VER MÁS</Link>
                </MoreInfo>

            </div>            
        </div>
        )
    }
}

export default Celulitis;