import React from 'react';
import {Link} from 'react-router-dom';
import './../App.css';

class MoreInfo extends React.Component{
    render(){
        return(
            <div className="moreinfo-section">
                <div className="moreinfo-image">
                    <img src={this.props.image} style={{width:'100%'}} />
                </div>
                <div className="moreinfo-text" >
                    <span style={{marginBottom:'1rem', fontSize:'2rem'}} className="texto-pink texto-light">{this.props.title}</span>
                    <span style={{marginBottom:'auto'}}>{this.props.text}</span>
                    {this.props.children}
                </div>
            </div>
        )
    }}
    export default MoreInfo;