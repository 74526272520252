import React from 'react';
import './App.css';
import MenuSup from './MenuSup.js';
import PieDePagina from './PieDePagina.js';
import Home from './views/home.js';
import Celulitis from './views/celulitis.js';
import Tratamiento from './views/tratamiento.js';
import AntesDespues from './views/antesdespues.js';
import Social from './views/social.js';
import Diagnostico from './views/diagnostico.js';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import ScrollToTop from './ScrollTop';


function App() {
  return (
    <Router>
      <ScrollToTop>
      <header  id="encabezado">
        <MenuSup />
      </header>       
      <div id="contenido">           
        <Switch>
          <Route key="index" exact path="/" >
            <Home />   
          </Route>
          <Route key="celulitis" exact path="/celulitis">
            <Celulitis />
          </Route>
          <Route key="tratamiento" exact path="/tratamiento">
            <Tratamiento />
          </Route>
          <Route key="antesdespues" exact path="/antesdespues">
            <AntesDespues />
          </Route>
          <Route key="social" exact path="/social">
            <Social />
          </Route>
          <Route key="diagnostico" exact path="/diagnostico">
            <Diagnostico />
          </Route>

        </Switch>
      </div>
      <PieDePagina/>   
      </ScrollToTop>      
    </Router>
  );
}

export default App;