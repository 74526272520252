import React from "react";
import './App.css';
export default class Modal extends React.Component {
    onClose = e => {
        this.props.onClose && this.props.onClose(e);
      };
    render() {
        if (!this.props.show) {
          return null;
        }
        return (
            <div className="Modal" onClick={this.props.onClose}>
              <div>{this.props.children}</div>
            </div>
            
          );
    
  }
}