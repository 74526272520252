import React from 'react';
import '../App.css';
import {Link} from 'react-router-dom';

import BannerHeader from '../BannerHeader';

import banner1 from '../images/1x/cellfinate_banner_victoria_contra_celulitis.jpg';
import banner2 from '../images/1x/cellfinate_banner_preocupacion.jpg';
import banner3 from '../images/1x/cellfinate_banner_resultados.jpg';
import banner4 from '../images/1x/cellfinate_banner_social.jpg';
import banner5 from '../images/1x/cellfinate_banner_encuentra_doctor.jpg';

import banner1_mobile from '../images/1x/cellfinate_banner_victoria_contra_celulitis_mobile.jpg';
import banner2_mobile from '../images/1x/cellfinate_banner_preocupacion_mobile.jpg';
import banner3_mobile from '../images/1x/cellfinate_banner_resultados_mobile.jpg';
import banner4_mobile from '../images/1x/cellfinate_banner_social_mobile.jpg';
import banner5_mobile from '../images/1x/cellfinate_banner_encuentra_doctor_mobile.jpg';

import certificaciones from '../images/1x/cellfinate_certificaciones.png';
import bolitas from '../images/1x/cellfinate_fondo_bolitas.jpg';

class Home extends React.Component{
    render(){
        var banners = [
            {
                imagen: banner1,
                imagen_mobile: banner1_mobile,
                link: '/'
            },
            {
                imagen: banner2,
                imagen_mobile: banner2_mobile,
                link:'/celulitis'
            },
            {
                imagen: banner3,
                imagen_mobile: banner3_mobile,
                link:'/antesdespues'
            },
            {
                imagen: banner4,
                imagen_mobile: banner4_mobile,
                link:'/social'
            },
        ]
    return( 
        <div className="Contenedor">
            <BannerHeader descripcion=''  banners={banners} />
            <div style={{backgroundColor:'#F0F0F0', minHeight:'600px', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}} >
                <div style={{width:'55%',  height:'400px',display:'flex',flexDirection:'column', alignItems: 'center', justifyContent:'space-around', fontSize:'1rem'}}>
                    <span className="texto-l texto-pink">
                        EL TRATAMIENTO
                    </span>
                    <span className="texto-m" style={{textAlign:'justify'}}>
                        Cellfinate es el único procedimiento mínimamente invasivo que elimina los hoyuelos de la celulitis . Clínicamente comprobado, avalado por la FDA y con garantía hasta por 5 años.
                    </span>
                    <a href="/tratamiento" className="button-pink texto-m">
                        VER MÁS
                    </a>
                </div>
            </div>
            <div style={{position:'relative', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}} >
                <div className="candidata-bg" style={{position:'absolute',width:'100%', opacity:'0.75'}}>
                    <img src={bolitas} style={{width:'100%', height:'100%', objectFit:'cover'}}/>
                </div>
                <div className="soy-candidata" style={{zIndex:1001}} >
                    <span className="texto-l texto-pink">
                        ¿SOY CANDIDATA?
                    </span>
                    <span className="texto-m">
                        <span>
                            Existen diversos tipos de celulitis, para garantizarte los resultados que estás buscando, evalúa si eres candidata para el tratamiento realizando este breve diagnóstico. 
                        </span>
                        <br/>
                        <span className="texto-bold">
                            ¡HOY ES TU VICTORIA CONTRA LA CELULITIS! 
                        </span>
                    </span>
                    <Link to="/diagnostico" className="button-pink texto-m" style={{backgroundColor:'white'}}>
                        HACER DIAGNÓSTICO
                    </Link>
                </div>
            </div>           
            <div style={{margin:'5rem auto', backgroundColor:'white', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}} >
                <div style={{width:'70%', display:'flex',flexDirection:'column', alignItems: 'center', justifyContent:'space-around', fontSize:'1rem'}}>
                    <span className="texto-l texto-pink">
                        CERTIFICACIONES
                    </span>
                    <span>
                        <img src={certificaciones} style={{width:'100%'}}/>
                    </span>
                </div>
            </div>
            <div style={{width:'100%', marginBottom:'0'}} >
                <a href="https://www.cellfinate.com.mx/doctor.html" target="_blank" rel="noopener noreferrer">
                    <img style={{width:'100%'}} src={banner5} />
                </a>
            </div>
        </div>
        )
    }
}

export default Home;