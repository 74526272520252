import React from 'react';
import '../App.css';
import {Link} from 'react-router-dom';

import BannerHeader from '../BannerHeader';

import banner from '../images/1x/cellfinate_banner_que_es_cellfina.jpg';
import banner_mobile from '../images/1x/cellfinate_banner_que_es_cellfina_mobile.jpg';

import tratamiento from '../images/1x/cellfinate_works_before_after.jpg';

import antes from '../images/1x/cellfinate_works_results.png';

import bolitas from '../images/1x/cellfinate_fondo_bolitas.png';

class Tratamiento extends React.Component{
    render(){
        var banners = [
            {
                imagen: banner,
                imagen_mobile: banner_mobile
            }
        ]
    return( 
        <div className="Contenedor">
            <BannerHeader descripcion=''  banners={banners} />
            <div style={{backgroundColor:'white',  display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-around'}} >
                <div style={{width:'80%',  height:'300px',display:'flex',flexDirection:'column', alignItems: 'center', justifyContent:'space-around', fontSize:'1rem'}}>
                    <span className="texto-l texto-pink">
                        ¿CÓMO FUNCIONA?
                    </span>
                    <span className="texto-m" style={{textAlign:'justify'}}>
                    El sistema Cellfina® trata la causa principal de la celulitis: las bandas de tejido conectivo que se forman a través de la grasa en los muslos y glúteos. Estas bandas de estructura tensa, tiran la piel hacia abajo, creando la textura de hoyuelos que se percibe en la superficie de la piel. Similar a una liga bajo tensión, una vez liberada, la piel tratada se recupera para alisarse en tan solo unos días*. Este tratamiento único contra la celulitis se realiza en el consultorio de un especialista, es mínimamente invasivo, y require de una sola sesión. 
                    </span>
                    <span>
                        *La apariencia y resultados podrán variar según la paciente.
                    </span>
                </div>
                <div className="antesdespues-image"> 
                    <img src={tratamiento} style={{width:'100%'}} />
                </div>
                <div style={{width:'50%', display:'flex', flexDirection: 'column', alignItems:'center', justifyContent:'center'}}>
                    <Link to="/antesdespues" className="button-pink texto-m">VER RESULTADOS</Link>
                </div>
            </div>
            <div style={{marginTop:'5rem', backgroundColor:'white', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-around'}} >
                <div style={{width:'80%', display:'flex',flexDirection:'column', alignItems: 'center', justifyContent:'space-around', fontSize:'1rem'}}>
                    <span className="texto-l texto-pink" style={{marginBottom:'2rem', textAlign:'justify'}}>
                        RESULTADOS ANTES Y DESPUÉS
                    </span>
                    <span className="texto-m" style={{textAlign:'justify'}}>
                        Galería de fotos de pacientes de antes y después del procedimiento.<br/> Las fotos no han sido retocadas. Son los resultados reales de Cellfinate.
                    </span>
                </div>
                <div className="antesdespues-image">
                    <img src={antes} alt="foto comparativa antes yu despues" style={{width:'100%'}}/>
                </div>
            </div>
            <div style={{position:'relative', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}} >
                <div className="candidata-bg" style={{position:'absolute',width:'100%', opacity:'0.75'}}>
                    <img src={bolitas} style={{width:'100%', height:'100%', objectFit:'cover'}}/>
                </div>
                <div className="soy-candidata" style={{zIndex:1001}} >
                    <span className="texto-l texto-pink">
                        ¿SOY CANDIDATA?
                    </span>
                    <span className="texto-m">
                        <span>
                            Existen diversos tipos de celulitis, para garantizarte los resultados que estás buscando, evalúa si eres candidata para el tratamiento realizando este breve diagnóstico. 
                        </span>
                        <br/>
                        <span className="texto-bold">
                            ¡HOY ES TU VICTORIA CONTRA LA CELULITIS! 
                        </span>
                    </span>
                    <Link to="/diagnostico" className="button-pink texto-m" style={{backgroundColor:'white'}}>
                        HACER DIAGNÓSTICO
                    </Link>
                </div>
            </div>           
        </div>
        )   
    }
}

export default Tratamiento;