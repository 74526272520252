import React from 'react';
import './../App.css';

class Paciente extends React.Component{
    render(){
        return(
          <div className="paciente-post" style={{color:'#808080', textAlign:'left'}}>
            <div className="paciente-image" >
              <img src={this.props.imagen} style={{width:'100%'}} alt='Imagen de blog'/>
            </div>
            <div className="paciente-content">
              <div className='paciente-text'>
                <span className='texto-pink texto-m'>{this.props.nombre},</span>
                <span className='texto-pink texto-s'>{this.props.paciente}</span>
                <span className='texto-normal texto-raleway texto-s' style={{textAlign:'justify'}}>"{this.props.descripcion}".</span>
              </div>
            </div>
          </div>
        )
    }}
    export default Paciente;