import React from 'react';
import './App.css';

import {Link, NavLink} from 'react-router-dom';
import Modal from './Modal';
import galdisa_logo from './images/1x/cellfinate_logo.png';

import {ReactComponent as FBLogo} from './images/SVG/cellfinate_icono_facebook.svg';
import {ReactComponent as InstaLogo} from './images/SVG/cellfinate_icono_instagram.svg';
import especialista from './images/1x/cellfinate_especialistas.png';


class MenuSup extends React.Component {

    toggleMenu = function() {
        var x = window.document.getElementById("topNav");
        if (x.className === "Encabezado") {
            x.className += " responsive";
        } else {
            x.className = "Encabezado";
        }
    }
    
    closeMenu = function(){
        var x = window.document.getElementById("topNav");
        x.className = "Encabezado";
    }
    
    componentDidUpdate(){
        console.log("updated");
        var x = window.document.getElementById("topNav");
        x.className = "Encabezado";
    }

state={
    showSpecial:false
}
render(){
        return(
            <>
            <div className="nav-sup">
                <div  className="Encabezado" id="topNav">
                    <div className="logo-container">
                        <Link className="Link" to="/" onClick={this.closeMenu}><img style={{width:'100%'}} className="logo" src={galdisa_logo} alt="Logo Cellfinate" /></Link>
                    </div>
                    <div className="menu-nav">
                        <NavLink className="Link" activeClassName="menu-selected" to="/celulitis" onClick={this.closeMenu}>LA CELULITIS</NavLink>
                        <NavLink className="Link" activeClassName="menu-selected" to="/tratamiento" onClick={this.closeMenu}>TRATAMIENTO</NavLink>
                        <NavLink className="Link" activeClassName="menu-selected" to="/antesdespues" onClick={this.closeMenu}>ANTES &amp; DESPUÉS</NavLink>
                        <NavLink className="Link" activeClassName="menu-selected" to="/social" onClick={this.closeMenu}>MEDIA BUZZ</NavLink>
                        <a href="https://www.cellfinate.com.mx/doctor.html" target="_blank" rel="noopener noreferrer">ENCUENTRA UN ESPECIALISTA</a>
                    </div>
                    <div className="menu-follow">
                        <div className="follow-us">
                            <div className="footer-social">
                                <a href="https://www.facebook.com/Cellfinate/" rel="noopener noreferrer" target="_blank" onClick={this.closeMenu}><FBLogo className="social-logo-pink"/></a>
                                <a href="https://instagram.com/cellfina" rel="noopener noreferrer" target="_blank" onClick={this.closeMenu}><InstaLogo className="social-logo-pink"/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="icon" onClick={this.toggleMenu}>
                        <i className="fas fa-2x fa-bars"></i>
                    </button>
            </div>
            <Modal onClose={()=>{this.setState({showSpecial:false})}} show={this.state.showSpecial}>
                <img className="modal-image" src={especialista} />
            </Modal>
            </>

        )
    }
}
export default MenuSup;

