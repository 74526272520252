import React, { Children } from 'react';
import './../App.css';

class ResultImage extends React.Component{
    render(){
        return(
            <div className="resultimage" >
                <img src={this.props.image} alt="foto antes" style={{width:'100%', marginBottom:'2rem'}}/>
                <span>{this.props.descripcion}</span>
            </div>
        )
    }}
    export default ResultImage;