import React from 'react';
import './App.css';

import {Link} from 'react-router-dom';

import Modal from './Modal';
import especialista from './images/1x/cellfinate_especialistas.png';

import logo from './images/SVG/cellfinate_icono_c.svg';
class PieDePagina extends React.Component {

state={
  showSpecial:false
}
render(){
    return(
      <>
      <Modal onClose={()=>{this.setState({showSpecial:false})}} show={this.state.showSpecial}>
        <img className="modal-image" src={especialista} />
      </Modal>
      <div className="PieDePagina">  
        <div className="footer-info">
          <div className="footer-left">
            <Link className="Link selected" to="/">HOME</Link>
            <Link className="Link" to="/tratamiento">TRATAMIENTO</Link>            
            <Link className="Link" to="/celulitis">LA CELULITIS</Link>
            <Link className="Link" to="/antesdespues">ANTES &amp; DESPUÉS</Link>
            <Link className="Link" to="/social">MEDIA BUZZ</Link> 
            <a href="https://www.cellfinate.com.mx/doctor.html" target="_blank" rel="noopener noreferrer">ENCUENTRA A UN ESPECIALISTA</a>
            <Link className="Link" activeClassName="menu-selected" to="/especialista" onClick={(evt)=>{evt.preventDefault(); this.setState({showSpecial:true})}}>SOY UN ESPECIALISTA</Link>
          </div>
          <div className="footer-center">
            <img src={logo} alt="Logo galdisa" />
          </div>
          <div className="footer-right">
            <div className="footer-data texto-white">
            Información de seguridad importante: El sistema Cellfina®️ está destinado a mejorar a largo plazo el aspecto de la celulitis en las zonas de los glúteos y los muslos de las mujeres adultas. No se ha establecido la seguridad y la eficacia en otras áreas anatómicas. Los efectos secundarios más comunes reportados fueron dolor, sensibilidad y moretones. El sistema Cellfina®️ sólo está disponible a través de un médico autorizado. Para obtener información completa sobre el producto y la seguridad, visite cellfina.com/IFU.

            </div>
          </div>
        </div>
      </div>
      </>
    )}
}
    export default PieDePagina;