import React from 'react';
import './App.css';

let timeout;
class BannerHeader extends React.Component {

    constructor(props){
        super();
        this.state = {
            banners: props.banners,
            selectedBanner: props.selected || 0
        };

    }

    updateSlide(){
        let newSelection = this.state.selectedBanner + 1;
        newSelection %= this.state.banners.length;
        clearTimeout(timeout);
        this.setState({
            selectedBanner: newSelection
        });
        timeout = setTimeout(()=>{
            this.updateSlide();
        }, 10000);
    }

    selectBanner(banner){
        console.log("Selecting banner  " + banner);
        clearTimeout(timeout);
        this.setState({
            selectedBanner: banner
        });
        timeout = setTimeout(()=>{
            this.updateSlide();
        }, 10000);
    }

    componentDidMount(){
        timeout = setTimeout(() => {
            this.updateSlide();
        }, 10000);
    }

    componentWillUnmount(){
        clearTimeout(timeout);
    }
    render(){
        let bolitas=<div className="selectors">
            {
                this.state.banners.map((banner, id)=>{
                    return <div key={"selector-"+id} onClick={()=>{this.selectBanner(id)}} className={"selector " + this.state.selectedBanner === id ? "active" : "" }>
                        <button className={"selector-dot " + (this.state.selectedBanner === id ? "dot-active": "")}>&nbsp;</button>
                    </div>
                })
            }
        </div>    
        return(
            <div href={this.state.banners[this.state.selectedBanner].link} id={this.props.id} className="Header" style={{width:'100%', position:'relative', ...this.props.style}} >
                <a href={this.state.banners[this.state.selectedBanner].link}>
                    <picture style={{width:'100%', height:'100%', objectFit:'cover'}}>
                        <source alt="Banner" style={{width:'100%', height:'100%', objectFit:'cover'}} srcSet={this.state.banners[this.state.selectedBanner].imagen_mobile} media="(max-width: 766px)" />
                        <source alt="Banner" style={{width:'100%', height:'100%', objectFit:'cover'}} srcSet={this.state.banners[this.state.selectedBanner].imagen} media="(min-width: 767px) "/>
                        <img alt="Banner" style={{width:'100%', height:'100%', objectFit:'cover'}} src={this.state.banners[this.state.selectedBanner].imagen} />
                    </picture>
                </a>
                {this.state.banners[this.state.selectedBanner].text}
                {this.state.banners.length > 1 && bolitas}
            </div>
        )
    }
}
export default BannerHeader;
