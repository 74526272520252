import React from 'react';
import '../App.css';

import BannerHeader from '../BannerHeader';

import banner from '../images/1x/cellfinate_banner_social.jpg';

import antes from '../images/1x/cellfinate_imagen_hoyuelos.jpg';

import social1 from '../images/1x/cellfinate_social_1.jpg';
import social2 from '../images/1x/cellfinate_social_2.jpg';
import social3 from '../images/1x/cellfinate_social_3.jpg';
import social4 from '../images/1x/cellfinate_social_4.jpg';
import social5 from '../images/1x/cellfinate_social_5.jpg';
import social6 from '../images/1x/cellfinate_social_6.jpg';

class AntesDespues extends React.Component{
    componentDidMount () {
        const script = document.createElement("script");
    
        script.src = "https://www.sociablekit.com/app/embed/facebook-feed/widget.js";
        script.async = true;
    
        document.body.appendChild(script);
    }
    
    render(){
        var banners = [
            {
                imagen: banner,
            }
        ]

    return( 
        <div className="Contenedor">
            <BannerHeader descripcion=''  banners={banners} />
            <div style={{marginTop:'5rem', backgroundColor:'white', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-around'}} >
                <div style={{width:'60%', marginBottom:'5rem', display:'flex',flexDirection:'column', alignItems: 'center', justifyContent:'space-around', fontSize:'1rem'}}>
                    <span className="texto-l texto-pink" style={{marginBottom:'2rem', width:'80%'}}>
                        #CELLFINATEMX
                    </span>
                    <span className="texto-m" style={{textAlign:'justify'}}>
                    Síguenos en nuestras redes sociales y entérate de todo sobre la celulitis. ¡Consejos, recomendaciones, dietas, ejercicios y más!
                    </span>
                </div>
                <div class='sk-ww-facebook-feed' data-embed-id='39412'></div>
            </div>
        </div>
        )   
    }
}

export default AntesDespues;