import React from 'react';
import '../App.css';

import BannerHeader from '../BannerHeader';

import banner from '../images/1x/cellfinate_banner_resultados.jpg';
import banner_mobile from '../images/1x/cellfinate_banner_resultados_mobile.jpg';

import cellfinate from '../images/1x/cellfinate_imagen_testimonio.png';

import antes from '../images/1x/cellfinate_antes_despues_antes.jpg';
import despues1 from '../images/1x/cellfinate_antes_despues_1.jpg';
import despues2 from '../images/1x/cellfinate_antes_despues_2.jpg';
import despues3 from '../images/1x/cellfinate_antes_despues_3.jpg';
import banner5 from '../images/1x/cellfinate_banner_encuentra_doctor.jpg';

import Paciente from '../components/Paciente'
import ResultImage from '../components/ResultImage';

class AntesDespues extends React.Component{
    render(){
        var banners = [
            {
                imagen: banner,
                imagen_mobile: banner_mobile
            }
        ]
    return( 
        <div className="Contenedor">
            <BannerHeader descripcion=''  banners={banners} />
            <div style={{marginTop:'5rem', backgroundColor:'white', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-around'}} >
                <div style={{width:'80%', marginBottom:'5rem', display:'flex',flexDirection:'column', alignItems: 'center', justifyContent:'space-around', fontSize:'1rem'}}>
                    <span className="texto-l texto-pink" style={{marginBottom:'2rem'}}>
                        RESULTADOS ANTES Y DESPUÉS
                    </span>
                    <span>
                        Galería de fotos de pacientes de antes y después del procedimiento. <br/> Las fotos no han sido retocadas. Son los resultados reales de Cellfinate.
                    </span>
                </div>
                <div style={{width:'100%', display:'flex', flexWrap:'wrap', flexDirection:'row', justifyContent:'space-around',alignItems:'flex-start'}}> 
                    <ResultImage image={antes} descripcion="Antes" />
                    <ResultImage image={despues1} descripcion="Después de 1 año" />
                    <ResultImage image={despues2} descripcion="Después de 2 años" />
                    <ResultImage image={despues3} descripcion="Despues de 3 años" />
                </div>
            </div>
            <div style={{width:'100%', paddingTop:'2rem', marginTop: '10rem', paddingBottom:'2rem', backgroundColor:'#F0F0F0', minHeight:'400px', display:'flex', flexDirection:'row', alignItems:'flex-start', justifyContent:'space-around', flexWrap:'wrap'}} >
                <Paciente imagen={cellfinate} nombre="Gina" paciente="Paciente" descripcion="Estaba maravillada con lo lisos que se veían mis glúteos. Desde Cellfinate, me siento hermosa y sensual"/>
                <Paciente imagen={cellfinate} nombre="Deanne M Robinson" paciente="Dermatóloga" descripcion="Como mujer, estoy realmente emocionada de ofrecer este procedimiento a mis pacientes. Conozco lo que estos hoyuelos ocasionados por la celulitis pueden afectar en la seguridad y autoestima. Disfruto tratar a mis pacientes con Cellfinate"/>
                <Paciente imagen={cellfinate} nombre="Paola" paciente="Paciente" descripcion="¡Me encantó ver mis resultados con Cellfinate! Ya necesitaba irme de compras para probarme esa ropa que pensé nunca compraría."/>
            </div>
  
            <div style={{width:'100%', marginBottom:'0'}} >
                <a href="https://www.cellfinate.com.mx/doctor.html" target="_blank" rel="noopener noreferrer" style={{width:'100%'}}>
                    <img style={{width:'100%'}} src={banner5} />
                </a>
            </div>

        </div>
        )   
    }
}

export default AntesDespues;