import React from 'react';
import '../App.css';

import BannerHeader from '../BannerHeader';

import banner from '../images/1x/cellfinate_banner_diagnostico.jpg';
import banner_mobile from '../images/1x/cellfinate_banner_diagnostico_mobile.jpg';

import cellfinate from '../images/1x/cellfinate_imagen_testimonio.png';

import {ReactComponent as Cadera } from '../images/SVG/cellfinate_icono_cadera.svg';
import {ReactComponent as Abdomen} from '../images/SVG/cellfinate_icono_abdomen.svg';
import {ReactComponent as Espalda} from '../images/SVG/cellfinate_icono_espalda.svg';
import {ReactComponent as Gluteos} from '../images/SVG/cellfinate_icono_gluteos.svg';
import banner5 from '../images/1x/cellfinate_banner_encuentra_doctor.jpg';

import hoyuelos from '../images/1x/cellfinate_imagen_hoyuelos.jpg';
import flacidez from '../images/1x/cellfinate_imagen_flacidez.jpg';
import ambos from '../images/1x/cellfinate_imagen_ambos.jpg';

import {ReactComponent as DensidadBaja} from '../images/SVG/cellfinate_densidad_baja.svg';
import {ReactComponent as DensidadMedia} from '../images/SVG/cellfinate_densidad_moderada.svg';
import {ReactComponent as DensidadAlta} from '../images/SVG/cellfinate_densidad_alta.svg';

import popupsi from '../images/1x/popup_si.png';
import popupno from '../images/1x/popup_no.png';

import Modal from '../Modal';
import Paciente from '../components/Paciente';
import ResultImage from '../components/ResultImage';
class Diagnostico extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            cadera:false,
            abdomen: false,
            espalda: false,
            gluteos: false,
            densidadBaja: false,
            densidadMedia: false,
            densidadAlta: false,
            hoyuelos: false,
            flacidez: false,
            both: false,
            showResult: false
        }
    }
    render(){
        var banners = [
            {
                imagen: banner,
                imagen_mobile: banner_mobile
            }
        ]
    return( 
        <div className="Contenedor">
            <BannerHeader descripcion=''  banners={banners} />
            <div style={{width:'100%', marginTop:'5rem', backgroundColor:'white', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-around'}} >
                <div style={{width:'90%', marginBottom:'5rem', display:'flex',flexDirection:'column', alignItems: 'center', justifyContent:'space-around', fontSize:'1rem'}}>
                    <span className="texto-m texto-pink" style={{fontSize:'1.5rem', marginBottom:'2rem', textTransform:'uppercase'}}>
                        ¿En qué zona(s) de tu cuerpo <br/> está localizada tu celulitis?
                    </span>
                    <span>
                        (puedes elegir más de una)
                    </span>
                </div>
                <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-around',alignItems:'center'}} className="diagnostico-options"> 
                    <div style={{width:'10%', height:'', display:'flex', flexDirection:'column', justifyContent:'space-around', alignItems:'center'}}>
                        <Cadera onClick={()=>{this.setState({cadera: !this.state.cadera})}} className={"diagnostic-icon " + (this.state.cadera? 'selected' : '')}  />
                        <span>Cadera</span>
                    </div>

                    <div style={{width:'10%', display:'flex', flexDirection:'column', justifyContent:'space-around', alignItems:'center'}}>
                        <Abdomen onClick={()=>{this.setState({abdomen: !this.state.abdomen})}} className={"diagnostic-icon " + (this.state.abdomen? 'selected' : '')}  />
                        <span>Abdomen</span>
                    </div>
                    <div style={{width:'10%', display:'flex', flexDirection:'column', justifyContent:'space-around', alignItems:'center'}}>
                        <Espalda onClick={()=>{this.setState({espalda: !this.state.espalda})}} className={"diagnostic-icon " + (this.state.espalda? 'selected' : '')}  />
                        <span>Espalda</span>
                    </div>                
                    <div style={{width:'10%', display:'flex', flexDirection:'column', justifyContent:'space-around', alignItems:'center'}}>
                        <Gluteos onClick={()=>{this.setState({gluteos: !this.state.gluteos})}} className={"diagnostic-icon " + (this.state.gluteos? 'selected' : '')}  />
                        <span>Gluteos</span>
                    </div>                

                </div>
            </div>
            <div style={{width:'100%', marginTop:'5rem', backgroundColor:'white', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-around'}} >
                <div style={{width:'80%', marginBottom:'5rem', display:'flex',flexDirection:'column', alignItems: 'center', justifyContent:'space-around', fontSize:'1rem'}}>
                    <span className="texto-l texto-pink" style={{fontSize:'1.5rem', marginBottom:'2rem', textTransform:'uppercase'}}>
                        ¿Cuál de las siguientes imágenes  <br/> representa mejor tu tipo de <br/> celulitis?
                    </span>
                </div>
                <div style={{boxSizing:'border-box', display:'flex', flexDirection:'row', justifyContent:'space-around',alignItems:'flex-start'}} className="diagnostico-options">  
                    <div style={{width:'30%', display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'center'}}>
                        <img onClick={()=>{this.setState({hoyuelos:true, flacidez:false, both:false})}} className={"diagnostic-image " + (this.state.hoyuelos ? 'selected' : '')} src={hoyuelos} alt="foto antes" style={{width:'100%', marginBottom:'2rem'}}/>
                        <span>Hoyuelos</span>
                    </div>

                    <div style={{width:'30%', display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'center'}}>
                        <img onClick={()=>{this.setState({hoyuelos:false, flacidez:true, both:false})}} className={"diagnostic-image " + (this.state.flacidez ? 'selected' : '')}src={flacidez} alt="foto despues de un año" style={{width:'100%', marginBottom:'2rem'}}/>
                        <span>Flacidez</span>
                    </div>
                    <div style={{width:'30%', display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'center'}}>
                        <img onClick={()=>{this.setState({hoyuelos:false, flacidez:false, both:true})}} className={"diagnostic-image " + (this.state.both ? 'selected' : '')} src={ambos} alt="foto despues de dos años" style={{width:'100%', marginBottom:'2rem'}}/>
                        <span>Hoyuelos y flacidez</span>
                    </div>                
                </div>
            </div>
            <div style={{width:'100%', marginTop:'5rem', backgroundColor:'white', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-around'}} >
                <div style={{width:'80%', marginBottom:'5rem', display:'flex',flexDirection:'column', alignItems: 'center', justifyContent:'space-around', fontSize:'1rem'}}>
                    <span className="texto-l texto-pink" style={{fontSize:'1.5rem',marginBottom:'2rem', textTransform:'uppercase'}}>
                        Evalúa el nivel de gravedad <br/> de tu celulits
                    </span>
                </div>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around',alignItems:'center'}} className="diagnostico-options"> 

                    <div style={{width:'15%', display:'flex', flexDirection:'column', justifyContent:'space-around', alignItems:'center'}}>
                        <DensidadBaja onClick={()=>{this.setState({densidadBaja: !this.state.densidadBaja, densidadMedia: false, densidadAlta: false})}} className={"diagnostic-icon " + (this.state.densidadBaja? 'selected' : '')}  />
                        <span>Baja</span>
                    </div>

                    <div style={{width:'15%', display:'flex', flexDirection:'column', justifyContent:'space-around', alignItems:'center'}}>
                        <DensidadMedia onClick={()=>{this.setState({densidadBaja:false, densidadMedia: !this.state.densidadMedia, densidadAlta: false})}} className={"diagnostic-icon " + (this.state.densidadMedia? 'selected' : '')}  />
                        <span>Moderada</span>
                    </div>
                    <div style={{width:'15%', display:'flex', flexDirection:'column', justifyContent:'space-around', alignItems:'center'}}>
                        <DensidadAlta onClick={()=>{this.setState({densidadBaja:false, densidadMedia: false, densidadAlta: !this.state.densidadAlta})}} className={"diagnostic-icon " + (this.state.densidadAlta? 'selected' : '')}  />
                        <span>Alta</span>
                    </div>                
                </div>
            </div>


            <button onClick={()=>{this.setState({showResult:true})}} className="button-pink" style={{backgroundColor:'transparent', marginTop:'10rem', outline:'none', cursor:'pointer'}}>
                HACER DIAGNÓSTICO
            </button>
            
            <div style={{width:'100%', paddingTop:'2rem', marginTop: '10rem', paddingBottom:'2rem', backgroundColor:'#F0F0F0', minHeight:'400px', display:'flex', flexDirection:'row', alignItems:'flex-start', justifyContent:'space-around', flexWrap:'wrap'}} >
                <Paciente imagen={cellfinate} nombre="Gina" paciente="Paciente" descripcion="Estaba maravillada con lo lisos que se veían mis glúteos. Desde Cellfinate, me siento hermosa y sensual"/>
                <Paciente imagen={cellfinate} nombre="Deanne M Robinson" paciente="Dermatóloga" descripcion="Como mujer, estoy realmente emocionada de ofrecer este procedimiento a mis pacientes. Conozco lo que estos hoyuelos ocasionados por la celulitis pueden afectar en la seguridad y autoestima. Disfruto tratar a mis pacientes con Cellfinate"/>
                <Paciente imagen={cellfinate} nombre="Paola" paciente="Paciente" descripcion="¡Me encantó ver mis resultados con Cellfinate! Ya necesitaba irme de compras para probarme esa ropa que pensé nunca compraría."/>
            </div>
            <Modal onClose={()=>{this.setState({showResult:false})}} show={this.state.showResult}>
                <img className='modal-image' src={(this.state.hoyuelos || this.state.both) ? popupsi : popupno} />
            </Modal>
        </div>
        )   
    }
}

export default Diagnostico;